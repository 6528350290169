export const SUBMIT_MID_CONTRACT = 'SUBMIT_MID_CONTRACT';

// The function, user, is an 'action creator'
// The return value is an 'action'
export function submitMidContract(input) {
  return {
    type: SUBMIT_MID_CONTRACT,
    payload: {
      midContract: input
    }
  }
}
