import React from 'react'
import "./footer.css"
const Footer = () => {
  return (
    <div className="container">
      <div className="disclaimer">***Disclaimer: It is suggested to claim your tokens as soon as available. MANDOX reserves the right to adjust reward amounts at any point to fit the best interests of the community and the future of the project. Rewards are estimated and may not be 100% accurate each claim. </div>
      <div className="row justify-content-between mt-5">
        <div className="col-md-4 col-5 footer-text mt-5">
          <p className='text-start'> ©2022 - MANDOX - We are EVA </p>
        </div>
        <div className="col-md-4 col-5 footer-text mt-5">
          <p className='text-end'>
            #BossGirls
          </p>
        </div>
      </div>
    </div>
  )
}
export default Footer;
